import formatdate from "./format"
import store from "@/store"
import {
    IpRegionMap,
    ProductTypeMap,
    LineStatusMap
} from "./constants"
import {
    h
} from "vue"


//云手机列表
const phoneAllList = [{
    prop: "Name",
    label: "名称",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editename(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }

}, {
    prop: "Id",
    label: "ID",
    width: "140",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "ProductId",
    label: "规格",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "universal") {
            return '通用版'
        } else if (cellValue == "live") {
            return '直播版'
        } else if (cellValue == "matrix") {
            return '矩阵版'
        } else if (cellValue == "phone-advanced") {
            return '精品版'
        } else if (cellValue == "phone-shared") {
            return '共享版'
        } else if (cellValue == "phone-exclusive") {
            return '独享版'
        } else if (cellValue == "phone-noip") {
            return '无网版'
        } else if (cellValue == "phone-social") {
            return '天晴定制版'
        } else if (cellValue == "phone-matrix") {
            return '矩阵1:4版'
        } else if (cellValue == "phone-ip") {
            return '精品IP版'
        }else if (cellValue == "phone-matrix8") {
            return '矩阵1:8版'
        }else if (cellValue == "phone-matrix16") {
            return '矩阵1:16版'
        } else {
            return cellValue
        }
    }
}, {
    prop: "SubAccount",
    label: "子账号",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    filter: true,
    filters: store.getters.filterlist,
    formatter: (row, column, cellValue, index) => {
        if (cellValue) {
            return cellValue
        } else {
            return "-"
        }
    }
}, {
    prop: "IpRegionName",
    label: "IP地域",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "IP",
    label: "IP",
    width: "130",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "State",
    label: "状态",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "UNDEFINED_STATE") {
            return '未知'
        } else if (cellValue == "STARTING") {
            return '开机中'
        } else if (cellValue == "START_FAILED") {
            return "开机失败"
        } else if (cellValue == "STOPPING") {
            return "关机中"
        } else if (cellValue == "STOP_FAILED") {
            return "关机失败"
        } else if (cellValue == "STOPPED") {
            return "已关机"
        } else if (cellValue == "RESETTING") {
            return "重置中"
        } else if (cellValue == "RESET_FAILED") {
            return "重置失败"
        } else if (cellValue == "SETTING_ROOT") {
            return "设置Boot中"
        } else if (cellValue == "SET_ROOT_FAILED") {
            return "设置Boot失败"
        } else if (cellValue == "SETTING_GPS") {
            return "设置GPS中"
        } else if (cellValue == "SET_GPS_FAILED") {
            return "设置GPS失败"
        } else if (cellValue == "SETTING_CONFIG") {
            return "设置分辨率中"
        } else if (cellValue == "SET_CONFIG_FAILED") {
            return "设置分辨率失败"
        } else if (cellValue == "SETTING_SOCKS") {
            return "配置IP中"
        } else if (cellValue == "SET_SOCKS_FAILED") {
            return "配置IP失败"
        } else if (cellValue == "RENEWING") {
            return "更改机型中"
        } else if (cellValue == "RENEW_FAILED") {
            return "更改机型失败"
        } else if (cellValue == "CREATE_FAILED") {
            return "创建失败"
        } else if (cellValue == "DELETING") {
            return "删除中"
        } else if (cellValue == "DELETE_FAILED") {
            return "删除失败"
        } else if (cellValue == "RUNNING") {
            return '运行中'
        } else if (cellValue == "CREATING") {
            return '创建中'
        } else if (cellValue == "REBOOTING") {
            return '重启中'
        } else if (cellValue == "REBOOT_FAILED") {
            return "重启失败"
        } else if (cellValue == "DUE") {
            return '已过期'
        } else {
            return "运行中"
        }
    }
}, {
    prop: "RemainTime",
    label: "剩余时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue
    }
}, {
    prop: "CreateTime",
    label: "创建时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "ExpireTime",
    label: "到期时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "GroupName",
    label: "业务组",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editegroup(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }
},
{
    prop: "OrderGroupId",
    label: "订单组",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
   
},
{
    prop: "TeamGroupId",
    label: "工作组",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "0") {
            return '工作组1'
        } else if (cellValue == "1") {
            return '工作组2'
        } else if (cellValue == "2") {
            return "工作组3"
        } else if (cellValue == "3") {
            return "工作组4"
        } else if (cellValue == "4") {
            return "工作组5"
        } else if (cellValue == "5") {
            return "工作组6"
        }else if (cellValue == "6") {
            return "工作组7"
        }else if (cellValue == "7") {
            return "工作组8"
        }else if (cellValue == "8") {
            return "工作组9"
        }else if (cellValue == "9") {
            return "工作组10"
        }else if (cellValue == "10") {
            return "工作组11"
        }else if (cellValue == "11") {
            return "工作组12"
        }else if (cellValue == "12") {
            return "工作组13"
        }else if (cellValue == "13") {
            return "工作组13"
        }else if (cellValue == "14") {
            return "工作组14"
        }else if (cellValue == "15") {
            return "工作组15"
        }else{
            return "------"
        }
    }
},
{
    prop: "AutoRenew",
    label: "自动续费",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == 0) {
            return '不开启'
        } else if (cellValue == 30) {
            return '按月'
        } else if (cellValue == 90) {
            return "按季度"
        } else if (cellValue == 180) {
            return "按半年"
        } else if (cellValue == 360) {
            return "按年"
        } else{
            return "------"
        }
    }
},
]

//云手机列表
const phoneList = [{
        width: "30",
        align: 'left',
        selection: true,
        reserve: true,
    }, {
        prop: "Name",
        label: "名称",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        formatter: (row, column, cellValue, index) => {
            let a = {}
            if (row.isShowBtn) {
                a = {
                    class: 'icon-edit-name iconfont icon-bianji',
                    style: {
                        'margin-left': '6px',
                        'cursor': 'pointer'
                    },
                    on: {
                        'click': () => {
                            editename(row)
                        }
                    }
                }
            }
            return h('div', [
                cellValue,
                h('span',
                    a,
                ),
            ])
        }

    }, {
        prop: "Id",
        label: "ID",
        width: "140",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
    }, {
        prop: "ProductId",
        label: "规格",
        width: "80",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        formatter: (row, column, cellValue, index) => {
            if (cellValue == "universal") {
                return '通用版'
            } else if (cellValue == "live") {
                return '直播版'
            } else if (cellValue == "matrix") {
                return '矩阵版'
            } else if (cellValue == "phone-advanced") {
                return '精品版'
            } else if (cellValue == "phone-shared") {
                return '共享版'
            } else if (cellValue == "phone-exclusive") {
                return '独享版'
            } else if (cellValue == "phone-noip") {
                return '无网版'
            } else if (cellValue == "phone-social") {
                return '天晴定制版'
            } else if (cellValue == "phone-matrix") {
                return '矩阵1:4版'
            } else if (cellValue == "phone-ip") {
                return '精品IP版'
            }else if (cellValue == "phone-matrix8") {
                return '矩阵1:8版'
            }else if (cellValue == "phone-matrix16") {
                return '矩阵1:16版'
            }else {
                return cellValue
            }
        }
    }
    /*, {
        prop: "SubAccount",
        label: "子账号",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        filter: true,
        filters: store.getters.filterlist,
        formatter: (row, column, cellValue, index) => {
            if (cellValue) {
                return cellValue
            } else {
                return "-"
            }
        }
    }*/
    , {
        prop: "IpRegion",
        label: "IP地域",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        formatter: (row, column, cellValue, index) => {
            return IpRegionMap[cellValue] || cellValue
        }
    }, {
        prop: "IP",
        label: "IP",
        width: "130",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
    }, {
        prop: "State",
        label: "状态",
        width: "80",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        formatter: (row, column, cellValue, index) => {
            if (cellValue == "UNDEFINED_STATE") {
                return '未知'
            } else if (cellValue == "STARTING") {
                return '开机中'
            } else if (cellValue == "START_FAILED") {
                return "开机失败"
            } else if (cellValue == "STOPPING") {
                return "关机中"
            } else if (cellValue == "STOP_FAILED") {
                return "关机失败"
            } else if (cellValue == "STOPPED") {
                return "已关机"
            } else if (cellValue == "RESETTING") {
                return "重置中"
            } else if (cellValue == "RESET_FAILED") {
                return "重置失败"
            } else if (cellValue == "SETTING_ROOT") {
                return "设置Boot中"
            } else if (cellValue == "SET_ROOT_FAILED") {
                return "设置Boot失败"
            } else if (cellValue == "SETTING_GPS") {
                return "设置GPS中"
            } else if (cellValue == "SET_GPS_FAILED") {
                return "设置GPS失败"
            } else if (cellValue == "SETTING_CONFIG") {
                return "设置分辨率中"
            } else if (cellValue == "SET_CONFIG_FAILED") {
                return "设置分辨率失败"
            } else if (cellValue == "SETTING_SOCKS") {
                return "配置IP中"
            } else if (cellValue == "SET_SOCKS_FAILED") {
                return "配置IP失败"
            } else if (cellValue == "RENEWING") {
                return "更改机型中"
            } else if (cellValue == "RENEW_FAILED") {
                return "更改机型失败"
            } else if (cellValue == "CREATE_FAILED") {
                return "创建失败"
            } else if (cellValue == "DELETING") {
                return "删除中"
            } else if (cellValue == "DELETE_FAILED") {
                return "删除失败"
            } else if (cellValue == "RUNNING") {
                return '运行中'
            } else if (cellValue == "CREATING") {
                return '创建中'
            } else if (cellValue == "REBOOTING") {
                return '重启中'
            } else if (cellValue == "REBOOT_FAILED") {
                return "重启失败"
            } else if (cellValue == "DUE") {
                return '已过期'
            } else {
                return "运行中"
            }
        }
    }, {
        prop: "RemainTime",
        label: "剩余时间",
        width: "110",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        // sortable: "custom",
        formatter: (row, column, cellValue, index) => {
            return cellValue
        }
    },
    /* {
        prop: "CreateTime",
        label: "创建时间",
        width: "110",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, 'YYYY-MM-DD')
        }
    },{
        prop: "ExpireTime",
        label: "到期时间",
        width: "110",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, 'YYYY-MM-DD')
        }
    }, */
    {
        prop: "GroupName",
        label: "业务组",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        sortable: true,
        formatter: (row, column, cellValue, index) => {
            let a = {}
            if (row.isShowBtn) {
                a = {
                    class: 'icon-edit-name iconfont icon-bianji',
                    style: {
                        'margin-left': '6px',
                        'cursor': 'pointer'
                    },
                    on: {
                        'click': () => {
                            editegroup(row)
                        }
                    }
                }
            }
            return h('div', [
                cellValue,
                h('span',
                    a,
                ),
            ])
        }
    },
    
]

//矩阵云手机列表
const matrixphoneList = [{
    width: "30",
    align: 'left',
    selection: true,
    reserve: true,
}, {
    prop: "Name",
    label: "名称",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editename(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }

}, {
    prop: "Id",
    label: "ID",
    width: "140",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
},{
    prop: "ProductId",
    label: "规格",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "universal") {
            return '通用版'
        } else if (cellValue == "live") {
            return '直播版'
        } else if (cellValue == "matrix") {
            return '矩阵版'
        } else if (cellValue == "phone-advanced") {
            return '精品版'
        } else if (cellValue == "phone-shared") {
            return '共享版'
        } else if (cellValue == "phone-exclusive") {
            return '独享版'
        } else if (cellValue == "phone-noip") {
            return '无网版'
        } else if (cellValue == "phone-social") {
            return '天晴定制版'
        } else if (cellValue == "phone-matrix") {
            return '矩阵1:4版'
        } else if (cellValue == "phone-ip") {
            return '精品IP版'
        }else if (cellValue == "phone-matrix8") {
            return '矩阵1:8版'
        }else if (cellValue == "phone-matrix16") {
            return '矩阵1:16版'
        }else {
            return cellValue
        }
    }
},
/*, {
    prop: "SubAccount",
    label: "子账号",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    filter: true,
    filters: store.getters.filterlist,
    formatter: (row, column, cellValue, index) => {
        if (cellValue) {
            return cellValue
        } else {
            return "-"
        }
    }
}*/
// , {
//     prop: "IpRegion",
//     label: "IP地域",
//     width: "100",
//     align: 'left',
//     headerAlign: 'left',
//     selection: false,
//     index: false,
//     expand: false,
//     sortable: true,
//     formatter: (row, column, cellValue, index) => {
//         return IpRegionMap[cellValue] || cellValue
//     }
// }, 
{
    prop: "IP",
    label: "IP",
    width: "130",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
}, {
    prop: "State",
    label: "状态",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "UNDEFINED_STATE") {
            return '未知'
        } else if (cellValue == "STARTING") {
            return '开机中'
        } else if (cellValue == "START_FAILED") {
            return "开机失败"
        } else if (cellValue == "STOPPING") {
            return "关机中"
        } else if (cellValue == "STOP_FAILED") {
            return "关机失败"
        } else if (cellValue == "STOPPED") {
            return "已关机"
        } else if (cellValue == "RESETTING") {
            return "重置中"
        } else if (cellValue == "RESET_FAILED") {
            return "重置失败"
        } else if (cellValue == "SETTING_ROOT") {
            return "设置Boot中"
        } else if (cellValue == "SET_ROOT_FAILED") {
            return "设置Boot失败"
        } else if (cellValue == "SETTING_GPS") {
            return "设置GPS中"
        } else if (cellValue == "SET_GPS_FAILED") {
            return "设置GPS失败"
        } else if (cellValue == "SETTING_CONFIG") {
            return "设置分辨率中"
        } else if (cellValue == "SET_CONFIG_FAILED") {
            return "设置分辨率失败"
        } else if (cellValue == "SETTING_SOCKS") {
            return "配置IP中"
        } else if (cellValue == "SET_SOCKS_FAILED") {
            return "配置IP失败"
        } else if (cellValue == "RENEWING") {
            return "更改机型中"
        } else if (cellValue == "RENEW_FAILED") {
            return "更改机型失败"
        } else if (cellValue == "CREATE_FAILED") {
            return "创建失败"
        } else if (cellValue == "DELETING") {
            return "删除中"
        } else if (cellValue == "DELETE_FAILED") {
            return "删除失败"
        } else if (cellValue == "RUNNING") {
            return '运行中'
        } else if (cellValue == "CREATING") {
            return '创建中'
        } else if (cellValue == "REBOOTING") {
            return '重启中'
        } else if (cellValue == "REBOOT_FAILED") {
            return "重启失败"
        } else if (cellValue == "DUE") {
            return '已过期'
        } else {
            return "运行中"
        }
    }
}, 
// {
//     prop: "RemainTime",
//     label: "剩余时间",
//     width: "110",
//     align: 'left',
//     headerAlign: 'left',
//     selection: false,
//     index: false,
//     expand: false,
//     sortable: true,
//     // sortable: "custom",
//     formatter: (row, column, cellValue, index) => {
//         return cellValue
//     }
// },
/* {
    prop: "CreateTime",
    label: "创建时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
},{
    prop: "ExpireTime",
    label: "到期时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, */
// {
//     prop: "GroupName",
//     label: "业务组",
//     width: "100",
//     align: 'left',
//     headerAlign: 'left',
//     selection: false,
//     index: false,
//     expand: false,
//     sortable: true,
//     formatter: (row, column, cellValue, index) => {
//         let a = {}
//         if (row.isShowBtn) {
//             a = {
//                 class: 'icon-edit-name iconfont icon-bianji',
//                 style: {
//                     'margin-left': '6px',
//                     'cursor': 'pointer'
//                 },
//                 on: {
//                     'click': () => {
//                         editegroup(row)
//                     }
//                 }
//             }
//         }
//         return h('div', [
//             cellValue,
//             h('span',
//                 a,
//             ),
//         ])
//     }
// },
{
    prop: "TeamGroupId",
    label: "工作组",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "0") {
            return '工作组1'
        } else if (cellValue == "1") {
            return '工作组2'
        } else if (cellValue == "2") {
            return "工作组3"
        } else if (cellValue == "3") {
            return "工作组4"
        } else if (cellValue == "4") {
            return "工作组5"
        } else if (cellValue == "5") {
            return "工作组6"
        }else if (cellValue == "6") {
            return "工作组7"
        }else if (cellValue == "7") {
            return "工作组8"
        }else if (cellValue == "8") {
            return "工作组9"
        }else if (cellValue == "9") {
            return "工作组10"
        }else if (cellValue == "10") {
            return "工作组11"
        }else if (cellValue == "11") {
            return "工作组12"
        }else if (cellValue == "12") {
            return "工作组13"
        }else if (cellValue == "13") {
            return "工作组13"
        }else if (cellValue == "14") {
            return "工作组14"
        }else if (cellValue == "15") {
            return "工作组15"
        }else{
            return "------"
        }
    }
},

]

//矩阵组列表
const matrixgroupList = [{
    width: "30",
    align: 'left',
    selection: true,
    reserve: true,
}, {
    prop: "Name",
    label: "名称",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editematrixname(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }

}, {
    prop: "MatrixGroupId",
    label: "ID",
    width: "140",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
},

{
    prop: "ProductId",
    label: "规格",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "universal") {
            return '通用版'
        } else if (cellValue == "live") {
            return '直播版'
        } else if (cellValue == "matrix") {
            return '矩阵版'
        } else if (cellValue == "phone-advanced") {
            return '精品版'
        } else if (cellValue == "phone-shared") {
            return '共享版'
        } else if (cellValue == "phone-exclusive") {
            return '独享版'
        } else if (cellValue == "phone-noip") {
            return '无网版'
        } else if (cellValue == "phone-social") {
            return '天晴定制版'
        } else if (cellValue == "phone-matrix") {
            return '矩阵1:4版'
        } else if (cellValue == "phone-ip") {
            return '精品IP版'
        }else if (cellValue == "phone-matrix8") {
            return '矩阵1:8版'
        }else if (cellValue == "phone-matrix16") {
            return '矩阵1:16版'
        }else {
            return cellValue
        }
    }
},
{
    prop: "SubAccount",
    label: "子账号",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    filter: true,
    filters: store.getters.filterlist,
    formatter: (row, column, cellValue, index) => {
        if (cellValue) {
            return cellValue
        } else {
            return "-"
        }
    }
}, {
    prop: "IpRegion",
    label: "地区",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        return IpRegionMap[cellValue] || cellValue
    }
}, {
    prop: "RemainTime",
    label: "剩余时间",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    // sortable: "custom",
    formatter: (row, column, cellValue, index) => {
        return cellValue
    }
},
{
    prop: "GroupName",
    label: "业务组",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editegroup(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }
},
{
    prop: "AutoRenew",
    label: "自动续费",
    width: "110",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == 0) {
            return '不开启'
        } else if (cellValue == 30) {
            return '按月'
        } else if (cellValue == 90) {
            return "按季度"
        } else if (cellValue == 180) {
            return "按半年"
        } else if (cellValue == 360) {
            return "按年"
        } else{
            return "------"
        }
    }
},
{
    prop: "MatrixWorkGroupId",
    label: "运行中工作组",
    width: "150",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    sortable: true,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "0") {
            return '工作组1'
        } else if (cellValue == "1") {
            return '工作组2'
        } else if (cellValue == "2") {
            return "工作组3"
        } else if (cellValue == "3") {
            return "工作组4"
        } else if (cellValue == "4") {
            return "工作组5"
        } else if (cellValue == "5") {
            return "工作组6"
        }else if (cellValue == "6") {
            return "工作组7"
        }else if (cellValue == "7") {
            return "工作组8"
        }else if (cellValue == "8") {
            return "工作组9"
        }else if (cellValue == "9") {
            return "工作组10"
        }else if (cellValue == "10") {
            return "工作组11"
        }else if (cellValue == "11") {
            return "工作组12"
        }else if (cellValue == "12") {
            return "工作组13"
        }else if (cellValue == "13") {
            return "工作组13"
        }else if (cellValue == "14") {
            return "工作组14"
        }else if (cellValue == "15") {
            return "工作组15"
        }else{
            return "------"
        }
    }
},
]


const appList = [{
    width: "30",
    align: 'left',
    selection: true,
}, {
    prop: "appName",
    label: "应用名称",
    width: "200",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "appsize",
    label: "应用大小",
    width: "200",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "apptext",
    label: "备注",
    width: "200",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,

    formatter: (row, column, cellValue, index) => {
        let a = {}
        if (row.isShowBtn) {
            a = {
                class: 'icon-edit-name iconfont icon-bianji',
                style: {
                    'margin-left': '6px',
                    'cursor': 'pointer'
                },
                on: {
                    'click': () => {
                        editegroup(row)
                    }
                }
            }
        }
        return h('div', [
            cellValue,
            h('span',
                a,
            ),
        ])
    }
}]
//分组列表
const grouplist = [{
        prop: "Name",
        label: "分组名称",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "Id",
        label: "分组ID",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    /*{
        prop: "",
        label: "组内云手机数量",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }*/
]
//子账号列表
const subaccountlist = [{
    prop: "UserId",
    label: "用户ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: 'Username',
    label: '用户名',
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "NickName",
    label: "昵称",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}]
//订单列表
const orderList = [{
    prop: "OrderId",
    label: "订单ID",
    width: "145",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "OrderType",
    label: "订单类型",
    width: "130",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "NEW") {
            return "新增"
        } else if (cellValue == "RENEW") {
            return "续费"
        } else {
            return cellValue
        }
    }
}, {
    prop: "ResourceType",
    label: "资源类型",
    width: "130",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "PATH") {
            return '直播快'
        } else if (cellValue == "PHONE") {
            return '云手机'
        } else {
            return cellValue
        }
    }
}, {
    prop: "Total",
    label: "购买金额",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "CreateTime",
    label: "创建时间",
    width: "135",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "FinishTime",
    label: "完成时间",
    width: "135",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}]
//续费表格
const renewList = [{
    prop: "product_type",
    label: "产品类型",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "Path") {
            return '直播快'
        } else if (cellValue == "Phone") {
            return '云手机'
        } else {
            return cellValue
        }
    }
}, {
    prop: "region",
    label: "地域",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return IpRegionMap[cellValue] || cellValue
    }
}, {
    prop: "config_type",
    label: "配置类型",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "universal") {
            return '通用版'
        } else if (cellValue == "live") {
            return '直播版'
        } else if (cellValue == "matrix") {
            return '矩阵版'
        } else if (cellValue == "phone-advanced") {
            return '精品版'
        } else if (cellValue == "phone-shared") {
            return '共享版'
        } else if (cellValue == "phone-exclusive") {
            return '独享版'
        } else if (cellValue == "phone-noip") {
            return '无网版'
        } else if (cellValue == "phone-social") {
            return '天晴定制版'
        } else if (cellValue == "phone-matrix") {
            return '矩阵1:4版'
        } else if (cellValue == "phone-ip") {
            return '精品IP版'
        }else if (cellValue == "phone-matrix8") {
            return '矩阵1:8版'
        }else if (cellValue == "phone-matrix16") {
            return '矩阵1:16版'
        }else {
            return cellValue
        }
    }
}, {
    prop: "name",
    label: "资源名称",
    width: "120",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "group",
    label: "业务组",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "status",
    label: "状态",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        if (cellValue == "RUNNING") {
            return '运行中'
        } else if (cellValue == "CREATING") {
            return '创建中'
        } else {
            return cellValue
        }
    }
}]
// 直播加速续费列表
const lineRenewList = [{
        prop: "product_type",
        label: "产品类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            if (cellValue == "Path") {
                return '直播快'
            } else if (cellValue == "Phone") {
                return '云手机'
            } else {
                return cellValue
            }
        }
    },
    {
        prop: "name",
        label: "名称",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "ipRegion",
        label: "地域",
        width: "80",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            console.log(row)
            return IpRegionMap[cellValue] || cellValue
        }
    }, {
        prop: "productId",
        label: "配置类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return ProductTypeMap[cellValue] || cellValue
        }
    },
    {
        prop: "groupName",
        label: "业务组",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false
    }, {
        prop: "status",
        label: "状态",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return LineStatusMap[cellValue] || cellValue
        }
    }
]
//上传文件列表
const filecloumns = [{
    prop: "filename",
    label: "文件名",
    width: "150",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "filesize",
    label: "文件大小",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "filetype",
    label: "文件类型",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "fileprogress",
    label: "进度",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "filestate",
    label: "状态",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}]
//文件管理列表
const filestoragelist = [{
        prop: "FileName",
        label: "文件名",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false
    }, {
        prop: "FileType",
        label: "文件类型",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            if (cellValue == "FILE_SYNC") {
                return "云手机文件"
            } else {
                return "本地文件"
            }
        }
    },
    {
        prop: "Size",
        label: "文件大小(GB)",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false
    }, {
        prop: "UploadTime",
        label: "上传时间",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, 'YYYY-MM-DD')
        }
    }, {
        prop: "Remark",
        label: "文件备注",
        width: "120",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            if (cellValue) {
                return cellValue;
            } else {
                return "-";
            }
        },
    }
]
//渠道汇总
const channelmainaccount = [{
        prop: "CompanyName",
        label: "公司名称",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return h('div', [
                cellValue || "-",
                h('span', {
                    class: "icon-edit-name iconfont icon-bianji",
                    style: {
                        "margin-left": "6px",
                        "cursor": "pointer"
                    },
                    on: {
                        'click': () => {
                            editChannelCompany(row)
                        }
                    }
                }, )
            ])
        }
    }, {
        prop: "UserId",
        label: "无觅ID",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "PhoneNumber",
        label: "无觅手机号",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "PathCurrentCount",
        label: "直播快当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "PathDiscount",
        label: "直播快折扣",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "1.0"
        }
    }, {
        prop: "PhoneCurrentCount",
        label: "云手机当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "PhoneDiscount",
        label: "云手机折扣",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "1.0"
        }
    }, {
        prop: "RouterCount",
        label: "路由器当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },{
        prop: "Saler",
        label: "销售",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return h('div', [
                cellValue,
                h('span', {
                    class: "icon-edit-name iconfont icon-bianji",
                    style: {
                        "margin-left": "6px",
                        "cursor": "pointer"
                    },
                    on: {
                        'click': () => {
                            showSetInviter(row)
                        }
                    }
                }, )
            ])
        }
    }, {
        prop: "ChannelOuterId",
        label: "关联ID",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return h('div', [
                cellValue || "-",
                h('span', {
                    class: "icon-edit-name iconfont icon-bianji",
                    style: {
                        "margin-left": "6px",
                        "cursor": "pointer"
                    },
                    on: {
                        'click': () => {
                            editChannelOuterId(row)
                        }
                    }
                }, )
            ])
        }
    }, {
        prop: "Inviter",
        label: "邀请人",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            if (cellValue) {
                return cellValue;
            } else {
                return "-";
            }
        },
    }
]
//渠道客户数据
const channelsubaccount = [{
        prop: "CompanyName",
        label: "公司名称",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return h('div', [
                cellValue || "-",
                h('span', {
                    class: "icon-edit-name iconfont icon-bianji",
                    style: {
                        "margin-left": "6px",
                        "cursor": "pointer"
                    },
                    on: {
                        'click': () => {
                            editChannelCompany(row)
                        }
                    }
                }, )
            ])
        }
    },
    {
        prop: "PhoneNumber",
        label: "无觅手机号",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "CreateTime",
        label: "注册日期",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, 'YYYY-MM-DD')
        }
    },
    {
        prop: "UserId",
        label: "无觅ID",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "AuditStatus",
        label: "审核状态",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "PathDiscount",
        label: "直播快折扣",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "1.0"
        }
    }, {
        prop: "PathCurrentCount",
        label: "直播快当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "PhoneDiscount",
        label: "云手机折扣",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "1.0"
        }
    }, {
        prop: "PhoneCurrentCount",
        label: "云手机当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    }, {
        prop: "RouterCount",
        label: "路由器当前资源数",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },{
        prop: "ChannelOuterId",
        label: "关联ID",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return h('div', [
                cellValue || "-",
                h('span', {
                    class: "icon-edit-name iconfont icon-bianji",
                    style: {
                        "margin-left": "6px",
                        "cursor": "pointer"
                    },
                    on: {
                        'click': () => {
                            editChannelOuterId(row)
                        }
                    }
                }, )
            ])
        }
    }, {
        prop: "Inviter",
        label: "邀请人",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            if (cellValue) {
                return cellValue;
            } else {
                return "-";
            }
        },
    }
]
//渠道数据
const channelResrouce = [{
    prop: "PhoneNumber",
    label: "无觅手机号",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "UserId",
    label: "无觅ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "ResourceType",
    label: "资源类型",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "ResourceId",
    label: "资源ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "ProductId",
    label: "产品",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return ProductTypeMap[cellValue] || cellValue
    }
}, {
    prop: "RegionId",
    label: "地区",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return IpRegionMap[cellValue] || cellValue
    }
}, {
    prop: "Ip",
    label: "IP",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || "-"
    }
}, {
    prop: "Discount",
    label: "折扣",
    width: "50",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || '1.0'
    }
}, {
    prop: "CreateTime",
    label: "资源创建时间",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "ExpireTime",
    label: "资源到期时间",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "Status",
    label: "资源状态",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return LineStatusMap[cellValue] || cellValue
    }
}, {
    prop: "ChannelOuterId",
    label: "关联ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || "-"
    }
}]

//渠道所有账号数据
const channelMainResrouce = [{
    prop: "PhoneNumber",
    label: "无觅手机号",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "UserId",
    label: "无觅ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "ResourceType",
    label: "资源类型",
    width: "80",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "ResourceId",
    label: "资源ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false
}, {
    prop: "ProductId",
    label: "产品",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return ProductTypeMap[cellValue] || cellValue
    }
}, {
    prop: "RegionId",
    label: "地区",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return IpRegionMap[cellValue] || cellValue
    }
}, {
    prop: "Ip",
    label: "IP",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || "-"
    }
}, {
    prop: "Discount",
    label: "折扣",
    width: "50",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || '1.0'
    }
}, {
    prop: "CreateTime",
    label: "资源创建时间",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "ExpireTime",
    label: "资源到期时间",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return formatdate(cellValue, 'YYYY-MM-DD')
    }
}, {
    prop: "Status",
    label: "资源状态",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return LineStatusMap[cellValue] || cellValue
    }
}, {
    prop: "Saler",
    label: "销售",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "SalerId",
    label: "销售ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
}, {
    prop: "ChannelOuterId",
    label: "关联ID",
    width: "100",
    align: 'left',
    headerAlign: 'left',
    selection: false,
    index: false,
    expand: false,
    formatter: (row, column, cellValue, index) => {
        return cellValue || "-"
    }
}]
//收入明细
const moneydetaillist = [{
        prop: "OrderId",
        label: "订单号",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "CreateTime",
        label: "交易时间",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, 'YYYY-MM-DD')
        }
    },
    {
        prop: "Type",
        label: "交易类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Total",
        label: "交易金额",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return "¥" + cellValue / 100.00;
        }
    }
]

const invoicelist = [{
        prop: "Id",
        label: "序号",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "InvoiceInfo.Type",
        label: "发票类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "InvoiceInfo.IssueType",
        label: "开具类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "InvoiceInfo.Title",
        label: "发票抬头",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "Amount",
        label: "金额（元）",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return (cellValue / 100.00).toFixed(2);
        }

    },
    {
        prop: "Remark",
        label: "发票备注（选填）",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Status",
        label: "状态",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    }
]

const invoicedetaillist = [{
        prop: "Type",
        label: "发票类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "IssueType",
        label: "开具类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Title",
        label: "发票抬头",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Medium",
        label: "发票介质",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Email",
        label: "邮箱",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "-"
        }

    },
    // {
    //     prop: "RecipientName",
    //     label: "收件人姓名",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // },
    // {
    //     prop: "RecipientPhone",
    //     label: "收件人电话",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // },
    // {
    //     prop: "RecipientAddress",
    //     label: "收件人地址",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // }
]
const invoicedetaillist2 = [{
        width: "30",
        align: 'left',
        selection: true,
    }, {
        prop: "Type",
        label: "发票类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "IssueType",
        label: "开具类型",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Title",
        label: "发票抬头",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Medium",
        label: "发票介质",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Email",
        label: "邮箱",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue || "-"
        }

    },
    // {
    //     prop: "RecipientName",
    //     label: "收件人姓名",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // },
    // {
    //     prop: "RecipientPhone",
    //     label: "收件人电话",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // },
    // {
    //     prop: "RecipientAddress",
    //     label: "收件人地址",
    //     width: "100",
    //     align: 'left',
    //     headerAlign: 'left',
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return cellValue || "-"
    //     }

    // }
]

const addressdetaillist = [{
        prop: "OrderId",
        label: "收件人姓名",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,
    },
    {
        prop: "CreateTime",
        label: "收件人电话",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    },
    {
        prop: "Type",
        label: "收件人地址",
        width: "100",
        align: 'left',
        headerAlign: 'left',
        selection: false,
        index: false,
        expand: false,

    }
]

export {
    phoneAllList,
    phoneList,
    matrixphoneList,
    matrixgroupList,
    grouplist,
    subaccountlist,
    orderList,
    renewList,
    lineRenewList,
    filecloumns,
    filestoragelist,
    channelmainaccount,
    channelsubaccount,
    channelResrouce,
    channelMainResrouce,
    moneydetaillist,
    invoicelist,
    invoicedetaillist,
    invoicedetaillist2,
    addressdetaillist,
    appList,
}